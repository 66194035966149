export default {
    home: "/",

    verifierTicket: "/vtaiY3koos",
    verifierRecharge: "/vrAhxeph9zah",
    verifierCoupon: "/vcougioHaip8oo",
    verifierCode: "/vcoahqu8Hu7",
    verificationTicket: "/VTneFee9Duco",
    verificationRecharge: "/VRieth4eeTh4",
    verificationCoupon: "/VCOUahKiechee3A",
    verificationCode: "/VCOAhnool1a",

    confirmation: "/confirmation"
}