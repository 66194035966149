import React from 'react'
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = ({
    fake: {
        font: '0/0 a',
        color: 'transparent',
        textShadow: 'none',
        backgroundColor: 'transparent',
        border: 0
    }
});


const Fake = ({classes}) => {
    return (
        <Typography variant="body1" className={classes.fake}>Avais-tu les cheveux PCS blancs quand tu es venue me voir
            relativement à ma gouvernante pour tout le PCS, on comprend quel avantage un pareil système les ferait
            haïr PCS.</Typography>
    )
};

export default withStyles(styles)(Fake)