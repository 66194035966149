import React, {Fragment} from 'react'
import { Link } from 'react-router-dom'
import {Helmet} from "react-helmet";
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography/Typography";
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import MainBg from '../PcsForm/MainBg.jpg'
import {SITE_NAME} from "../../Constants";


const styles = theme => ({
    container: {
        backgroundImage: `url(${MainBg})`,
        backgroundPosition: 'top',
        backgroundSize: 'cover',
        paddingTop: '4rem',
        paddingBottom: '3rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        minHeight: '550px'
    },
    h2: {
        fontSize: '32px',
        fontWeight: 700,
        textShadow: '1px 1px 1px rgba(0,0,0,.5)'
    },
    p: {
        fontSize: '18px',
        lineHeight: '27px',
        fontWeight: 400,
        paddingBottom: 16
    },
    formWrapper: {
        padding: '3rem',
        [theme.breakpoints.up(992)]: {
            width: '60%',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
    },
    pcsCode: {
        textTransform: 'uppercase'
    },
    textHide: {
        font: '0/0 a',
        color: 'transparent',
        textShadow: 'none',
        backgroundColor: 'transparent',
        border: 0
    },
    button: {
        marginTop: '24px'
    }
});

const PcsConfirmation = ({classes}) => {
    return (
        <Fragment>
            <Helmet>
                <title>{SITE_NAME} - Confirmation</title>
                <meta name="description" content={`Contrôlez en toute sécurité la validité de votre ticket PCS. Vérification 100% gratuite et instantanée. Un message de confirmation vous est envoyé par mail.`} />
                <meta name="robots" content="index" />
            </Helmet>
            <div className={classes.container}>
                <Grid container>
                    <Grid item md={6}>
                        <Typography className={classes.h2} align="center" variant="h2" gutterBottom>Confirmation</Typography>
                        <Paper className={classes.formWrapper} elevation={2}>
                            <Typography  variant="subtitle1" gutterBottom>Nous avons bien reçu votre demande de vérification.</Typography>
                            <Typography  variant="subtitle1" gutterBottom>Un mail vous sera envoyé afin de vous confirmer la validité de votre ticket <span className={classes.textHide}>PCS</span></Typography>
                            <Button component={Link} to="/" variant="contained" size="large" color="primary" fullWidth className={classes.button}>Vérifier un autre ticket PCS</Button>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </Fragment>
    )
};

export default withStyles(styles)(PcsConfirmation)