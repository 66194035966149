import React, {Fragment} from 'react'
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Advantages from "../Advantages/Advantages";
import PcsConfirmation from "../PcsConfirmation/PcsConfirmation";


const Confirmation = () => {
    return (
        <Fragment>
            <Header/>
            <PcsConfirmation/>
            <Advantages/>
            <Footer/>
        </Fragment>
    )
};

export default Confirmation