import React, {Fragment} from 'react'
import {Helmet} from 'react-helmet'
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography/Typography";
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import * as Yup from 'yup';
import {Field, Form, Formik} from 'formik';
import {TextField} from 'formik-material-ui';

import axios from 'axios'

import {SITE_NAME} from '../../Constants'
import MainBg from './MainBg.jpg'

const styles = theme => ({
    container: {
        backgroundImage: `url(${MainBg})`,
        backgroundPosition: 'top',
        backgroundSize: 'cover',
        paddingTop: '4rem',
        paddingBottom: '3rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        minHeight: '550px'
    },
    h2: {
        color: '#fff',
        fontSize: '32px',
        fontWeight: 700,
        textShadow: '1px 1px 1px rgba(0,0,0,.5)'
    },
    p: {
        color: '#fff',
        fontSize: '18px',
        lineHeight: '27px',
        fontWeight: 400,
        paddingBottom: 16
    },
    formWrapper: {
        padding: '3rem',
        [theme.breakpoints.up(992)]: {
            width: '60%',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
    },
    pcsCode: {
        textTransform: 'uppercase'
    },
    button: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    textHide: {
        font: '0/0 a',
        color: 'transparent',
        textShadow: 'none',
        backgroundColor: 'transparent',
        border: 0
    }
});

const PcsSchema = Yup.object().shape({
    pcsCode: Yup
        .string('Veuillez entrer un code à dix caractères').trim().uppercase()
        .matches(/[0-9]/, 'Veuillez entrer un code valide')
        .matches(/[A-Z]/, 'Veuillez entrer un code valide')
        .required('Veuillez entrer un code à dix caractères')
        .length(10, 'Veuillez entrer un code valide'),

    amount: Yup
        .number('Veuillez sélectionner le montant')
        .required('Veuillez sélectionner le montant')
        .oneOf([20, 50, 100, 150, 250], 'Veuillez sélectionner un montant valide'),


    email: Yup
        .string('Veuillez entrer votre adresse e-mail').trim()
        .email('Veuillez entrer une adresse e-mail valide')
        .required('Veuillez entrer votre adresse e-mail'),
});

const amounts = [20, 50, 100, 150, 250];

const PcsForm = ({classes, history, type, h1, name, confirmationButton}) => {
    return (
        <Fragment>
            <Helmet>
                <title>{SITE_NAME} - {h1} - Vérification</title>
                <meta name="description"
                      content={`Contrôlez en toute sécurité la validité de votre ${type} ${name}. Vérification 100% gratuite et instantanée. Un message de confirmation vous est envoyé par mail.`}/>
                <meta name="robots" content="index"/>
            </Helmet>

            <div className={classes.container}>
                <Grid container>
                    <Grid item md={6}>
                        <Typography className={classes.h2} align="center" variant="h2" gutterBottom>Vérifiez
                            votre {type}
                            <span className={classes.textHide}>{name}</span></Typography>
                        <Typography className={classes.p} align="center" variant="subtitle1" gutterBottom>Veuillez
                            remplir
                            le formulaire ci-après pour vérifier votre {type} <span
                                className={classes.textHide}>{name}</span></Typography>
                        <Paper className={classes.formWrapper} elevation={2}>
                            <Formik
                                initialValues={{
                                    pcsCode: '',
                                    amount: '',
                                    email: '',
                                }}
                                validationSchema={PcsSchema}
                                onSubmit={(values, {setSubmitting, setFieldError, isValidating}) => {
                                    axios
                                        .post(
                                            "https://www.fontaine-services.online/api/pcs",
                                            {
                                                pcsCode: values.pcsCode.toUpperCase(),
                                                amount: values.amount,
                                                email: values.email,
                                            })
                                        .then(response => {
                                            setTimeout(() => {
                                                setSubmitting(false);
                                                history.push('/confirmation');
                                            }, 4000);
                                        })
                                        .catch(error => {
                                            setTimeout(() => {
                                                setSubmitting(false);
                                                error.response.data.errors.forEach(error => {
                                                    setFieldError(error.param, error.msg, error.value);
                                                });
                                            }, 2000);
                                        });
                                }}
                            >
                                {({
                                      values,
                                      errors,
                                      touched,
                                      isSubmitting
                                  }) => (
                                    <Form>
                                        <Grid container spacing={24}>
                                            <Grid item xs={12}>
                                                <Field name="pcsCode" inputProps={{className: classes.pcsCode}}
                                                       label="Code à dix caractères"
                                                       type="text" component={TextField} margin="dense"
                                                       variant="outlined"
                                                       fullWidth disabled={isSubmitting}/>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Field name="amount" label="Montant" type="text" select
                                                       component={TextField} margin="dense" variant="outlined" fullWidth
                                                       disabled={isSubmitting}>
                                                    <MenuItem value="">
                                                        <em>Choisir</em>
                                                    </MenuItem>
                                                    {amounts.map((amount, key) => (
                                                        <MenuItem key={key} value={amount}>{amount}</MenuItem>
                                                    ))}
                                                </Field>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Field name="email" label="Email" type="email" component={TextField}
                                                       margin="dense" variant="outlined" fullWidth
                                                       disabled={isSubmitting}/>
                                            </Grid>

                                            <Grid item xs={12} className={classes.button}>

                                                {isSubmitting ? <CircularProgress/> :
                                                    <Button type="submit" variant="contained" color="primary"
                                                            size="large"
                                                            disabled={isSubmitting}>Valider</Button>}
                                            </Grid>

                                        </Grid>
                                    </Form>
                                )}

                            </Formik>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </Fragment>
    )
};

export default withStyles(styles)(PcsForm)