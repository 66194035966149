import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography/Typography";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';


import '../../fonts/Linearicons/Linearicons.css'

const styles = {
    h2: {
        fontSize: '32px',
        marginTop: '4rem',
        marginBottom: '3rem'
    },
    cardContent: {
        textAlign: 'center',
        minHeight: 260
    },

    text: {
        padding: '1.25rem'
    },
    icone: {
        paddingTop: '3rem',
        fontSize: '4.5rem',
        fontWeight: 300,
        lineHeight: 1.2,
        color: '#F39324',
    },
    textHide : {
        font: '0/0 a',
        color: 'transparent',
        textShadow: 'none',
        backgroundColor: 'transparent',
        border: 0
    },
    container: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
        marginBottom: '4rem',

    }
}

const Advantages = ({classes, advantage, type, name}) => {
    return (
        <div className={classes.container}>
            <Grid container justify="center" alignItems="center" spacing={32}>
                <Grid item xs={12}>
                    <Typography className={classes.h2} align="center" variant="h2" gutterBottom>{advantage} {type} <span className={classes.textHide}>{name}</span></Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={2}>
                    <Card className={classes.card} raised>
                        <CardContent className={classes.cardContent}>
                            <div className={`linear-icon-shield-check ${classes.icone}`}></div>
                            <Typography className={classes.text} variant="subtitle1">Contrôlez en toute sécurité la validité de votre {type} <span className={classes.textHide}>{name}</span></Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={2}>
                    <Card className={classes.card} raised>
                        <CardContent className={classes.cardContent}>
                            <div className={`linear-icon-power ${classes.icone}`}></div>
                            <Typography className={classes.text} variant="subtitle1">La vérification de votre {type} <span className={classes.textHide}>{name}</span> est instantanée</Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={2}>
                    <Card className={classes.card} raised>
                        <CardContent className={classes.cardContent}>
                            <div className={`linear-icon-cash-euro ${classes.icone}`}></div>
                            <Typography className={classes.text} variant="subtitle1">La vérification de votre {type} <span className={classes.textHide}>{name}</span> est 100% gratuite</Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={2}>
                    <Card className={classes.card} raised>
                        <CardContent className={classes.cardContent}>
                            <div className={`linear-icon-envelope-open ${classes.icone}`}></div>
                            <Typography className={classes.text} variant="subtitle1">Un message de confirmation vous est envoyé par mail</Typography>
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>
        </div>
    )
}

export default withStyles(styles)(Advantages)