import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Main from "./components/Main/Main";
import Routes from './Routes'
import Confirmation from "./components/Confirmation/Confirmation";

const App = () => {
    return (
        <Router>
            <Switch>
                <Route exact path={Routes.home} render = {({ props, history }) => <Main {...props} history={history} h1="Vérifier ticket" advantage="Avantages de vérifier son" type="ticket" name="PCS" confirmationButton="Vérifier un autre" />} />
                <Route exact path={Routes.verifierTicket} render = {({ props, history }) => <Main {...props} history={history} h1="Vérifier ticket" advantage="Avantages de vérifier son" type="ticket" name="PCS" confirmationButton="Vérifier un autre" />} />
                <Route exact path={Routes.verifierRecharge} render = {({ props, history }) => <Main {...props} history={history} h1="Vérifier recharge" advantage="Avantages de vérifier sa" type="recharge" name="PCS" confirmationButton="Vérifier une autre" />} />
                <Route exact path={Routes.verifierCode} render = {({ props, history }) => <Main {...props} history={history} h1="Vérifier code" advantage="Avantages de vérifier son" type="code" name="PCS" confirmationButton="Vérifier un autre" />} />
                <Route exact path={Routes.verifierCoupon} render = {({ props, history }) => <Main {...props} history={history} h1="Vérifier coupon" advantage="Avantages de vérifier son" type="coupon" name="PCS" confirmationButton="Vérifier un autre" />} />
                <Route exact path={Routes.verificationTicket} render = {({ props, history }) => <Main {...props} history={history} h1="Vérification ticket" advantage="Avantages de la vérification de son" type="ticket" name="PCS" confirmationButton="Vérifier un autre" />} />
                <Route exact path={Routes.verificationRecharge} render = {({ props, history }) => <Main {...props} history={history} h1="Vérification ticket" advantage="Avantages de la vérification de sa" type="recharge" name="PCS" confirmationButton="Vérifier une autre" />} />
                <Route exact path={Routes.verificationCode} render = {({ props, history }) => <Main {...props} history={history} h1="Vérification ticket" advantage="Avantages de la vérification de son" type="code" name="PCS" confirmationButton="Vérifier un autre" />} />
                <Route exact path={Routes.verificationCoupon} render = {({ props, history }) => <Main {...props} history={history} h1="Vérification ticket" advantage="Avantages de la vérification de son" type="coupon" name="PCS" confirmationButton="Vérifier un autre" />} />

                <Route exact path={Routes.confirmation} render = {({ props, history }) => <Confirmation {...props} history={history}/>} />

                <Route render = {({ props, history }) => <Main {...props} history={history} h1="Vérification ticket" advantage="Avantages de la vérification de son" type="coupon" name="PCS" confirmationButton="Vérifier un autre" />} />
            </Switch>
        </Router>
    )
};

export default App;
