import React from 'react'
import {ADDRESS, SITE_NAME} from '../../Constants'
import {withStyles} from '@material-ui/core/styles'
import Typography from "@material-ui/core/Typography/Typography";

const styles = {
    container: {
        backgroundColor: '#0F1418',
        padding: '1rem'
    },

    body2: {
        color: '#fff'
    }
};

const Footer = ({classes}) => {
    return(
        <div className={classes.container}>
            <Typography align="center" variant="body2" className={classes.body2}>{SITE_NAME} - {ADDRESS}</Typography>
        </div>
    )
};

export default withStyles(styles)(Footer)