import React from 'react'
import {SITE_NAME} from '../../Constants'
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const styles = {
    toolbar: {
        backgroundColor: '#0F1418'
    },
    typography: {
        color: '#fff',
        textTransform: 'uppercase'
    },
    textHide : {
        font: '0/0 a',
        color: 'transparent',
        textShadow: 'none',
        backgroundColor: 'transparent',
        border: 0
    }
};

const Header = ({classes, h1, name}) => {
    return (
        <AppBar position="static">
            <Toolbar className={classes.toolbar}>
                <Typography className={classes.textHide} variant="h1">{h1} {name}</Typography>
                <Typography className={classes.typography} variant="h6" component="h2">{SITE_NAME}</Typography>
            </Toolbar>
        </AppBar>
    )
};

export default withStyles(styles)(Header)