import React, {Fragment} from 'react'
import Header from "../Header/Header";
import PcsForm from "../PcsForm/PcsForm";
import Advantages from "../Advantages/Advantages";
import Footer from "../Footer/Footer";
import Fake from "../Fake/Fake";

const Main = ({...props}) => {
    return(
        <Fragment>
            <Header {...props}/>
            <PcsForm {...props}/>
            <Advantages {...props}/>
            <Fake/>
            <Footer/>
        </Fragment>
    )
}

export default Main